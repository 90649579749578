import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Dropdown,notification } from "antd";
import { Logo, User } from "../../svgIcons";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import ReactModal from "react-modal";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { DASHBOARD_SUPERADMIN_EMAILS } from '../../../utils/constant'

// import Styles from "./topNavigation.module.css"

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
export default function TopNavigationComponent(props) {
  const navigate = useNavigate();
  const { instance } = useMsal()
  const isAuthenticated  = useIsAuthenticated()
  const [modalIsOpen,setModalIsOpen]=useState(false)
  const [urlError,setUrlError]=useState("")
  const [isLoading,setIsLoading]=useState(false)
  const [url,setUrl]=useState(false)
  const logout = () => {
    // console.log(isAuthenticated,'isAuthenticated')
    localStorage.removeItem("Authorization");
    if(isAuthenticated){
      instance.logoutRedirect()
    }
    navigate("/login");
  };
  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      key: "updatable",
      message: "Liontree",
      description: message,
    });
  };
  const closeModal=()=>{
    setModalIsOpen(false)
    setIsLoading(false)
    setUrl("")
    setUrlError("")
  }
  const handleUrl=(value)=>{
    setUrl(value)
    setUrlError("")
  }
  const saveUrl=async()=>{
if(url==""||url==undefined||url==null){
  setUrlError("Please enter a valid theme url")
return
}
const siteUrl = process.env.REACT_APP_SITE_URL;

const urlPattern = new RegExp(
  `^${siteUrl}/LTWeekly/([A-Za-z]+-\\d{1,2}-\\d{4})/([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})$`
);

if (!url || !url.startsWith(siteUrl)) {
  setUrlError(`Please enter a valid theme URL that starts with ${siteUrl}`);
  return;
}

if (!urlPattern.test(url)) {
  setUrlError("URL must include a LT Weekly date and a valid theme ID.");
  return;
}

setIsLoading(true)
const payload={
  url:url
}
const { value } = await props.updateTopTheme(payload)
if(value.success){
  setIsLoading(false)
  openNotificationWithIcon(
    "success",
    "Top theme saved successfully."
  );
}else{
  setIsLoading(false)
  openNotificationWithIcon(
    "error",
    "Data can not be saved."
  );
}
   closeModal()
  }

  return (
    <>
      <header className="top__navigation top__navigation--fixed">
        <div className="top__navigation--container">
          <div className="top__navigation--row">
            <div className="top__navigation--left">
              <div className="top__navigation--logo">
                <Link to="/" className="top__navigation--brand">
                  {/* <img src={Logo} alt="Gen-Z Media"/> */}
                  <Logo className="header-logo" />
                </Link>
              </div>
            </div>
            <div className="top__navigation--right">
              <div className="top__navigation--nav">
                <>
                <button
                          onClick={() => {
                            setModalIsOpen(!modalIsOpen);
                          }}
                          type="button"
                          className="btn btn__purple me-2"
                        >
                          Featured Theme
                        </button>
                  <Dropdown
                    trigger={["click"]}
                    placement="topRight"
                    overlayClassName="top__navigation--dropdown"
                    overlay={
                      <Menu>
                        <div className="profile__wrapper">
                          <div className="profile__name">
                            {props?.userData?.FirstName}{" "}
                            {props?.userData?.LastName}
                          </div>
                          <div className="profile__role">{DASHBOARD_SUPERADMIN_EMAILS.includes(props?.userData?.EmailAddress) ? "Super Admin" : "Admin"}</div>
                        </div>
                        {DASHBOARD_SUPERADMIN_EMAILS.includes(props?.userData?.EmailAddress) &&
                          <Menu.Item key="1">
                            <Link
                              to="/administration/users"
                              className="top__navigation--dropdown-link"
                            >
                              Users
                            </Link>
                          </Menu.Item>
                        }
                        <Menu.Item key="2">
                          <Link
                            to="/account/profile"
                            className="top__navigation--dropdown-link"
                          >
                            Profile
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => logout()}
                          key="3"
                          className="top__navigation--dropdown-btn"
                        >
                          Sign out
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <a
                      className="ant-dropdown-link nav__link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <User />
                    </a>
                  </Dropdown>
                  <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel=""
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          shouldFocusAfterRender={true}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog react-modal-dialog-xs modal-dialog-centered send__other-news-modal"
        >
          <div className="modal-content">
            <div className="react-modal-header border-bottom">
              <h5 className="react-modal-title">Featured Theme</h5>
              {/* <button className="btn react-modal-close" onClick={closeModal}>
              <Close />
            </button> */}
            </div>
            <div className="react-modal-body">
            <div className="form-group">
            <label className="label-primary">Enter Top Theme Url Here</label>
                    <input
                      type="text"
                      name="url"
                      onChange={(e) => handleUrl(e.target.value)}
                      className="form-control mb-2"
                      // value={url}
                      required
                    />
                  <div style={{ color: "red", fontSize: "13px" }}>
                    {urlError === "" ? "" : urlError}
                  </div>
                </div>
              {/* </div> */}
            </div>
            <div className="react-modal-footer">
              <button
                onClick={() => {
                  closeModal();
                }}
                type="button"
                className="btn btn__default"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn__purple SubmitSave"
                onClick={()=>saveUrl()}
              >
                {isLoading ? <Spin indicator={antIcon} className="customSize"/> : "Submit"}
              </button>
            </div>
          </div>
        </ReactModal>
                </>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
