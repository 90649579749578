import { Record } from "immutable";
import { assign } from "lodash";
import { INIT, LOADING, SUCCESS, ERROR } from "../../constants/phase";

// import { fromPromise } from 'rxjs/observable/fromPromise'
import { from } from "rxjs";

import { of } from "rxjs";
import { mergeMap, flatMap, catchError } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";

import * as api from "./api";

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

// PHASE START
export const LTWEEKLY_DASHBOARD_GET_APP_USERS =
  "home/LTWEEKLY_DASHBOARD_GET_APP_USERS";
export const LTWEEKLY_DASHBOARD_LOGIN = "home/LTWEEKLY_DASHBOARD_LOGIN";
export const LTWEEKLY_LOGIN_VERIFY_OTP = "home/LTWEEKLY_LOGIN_VERIFY_OTP";
export const LTWEEKLY_DASHBOARD_LOGIN_SUCCESS =
  "home/LTWEEKLY_DASHBOARD_LOGIN_SUCCESS";
export const LTWEEKLY_DASHBOARD_LOGIN_ERROR =
  "home/LTWEEKLY_DASHBOARD_LOGIN_ERROR";
export const LTWEEKLY_DASHBOARD_GET_EVENT_DETAILS =
  "home/LTWEEKLY_DASHBOARD_GET_EVENT_DETAILS";
export const LTWEEKLY_DASHBOARD_GET_USER_DETAILS =
  "home/LTWEEKLY_DASHBOARD_GET_USER_DETAILS";
export const INIT_HOME_PHASE = "home/INIT_HOME_PHASE";
export const LTWEEKLY_GET_ME = "home/LTWEEKLY_GET_ME";
export const LTWEEKLY_DASHBOARD_GET_EVENTS =
  "home/LTWEEKLY_DASHBOARD_GET_EVENTS";
export const LTWEEKLY_DASHBOARD_ADD_EVENT = "home/LTWEEKLY_DASHBOARD_ADD_EVENT";
export const LTWEEKLY_DASHBOARD_USER_DELETE =
  "home/LTWEEKLY_DASHBOARD_USER_DELETE";
export const LTWEEKLY_DASHBOARD_ADD_APP_USER_TO_EVENT =
  "home/LTWEEKLY_DASHBOARD_ADD_APP_USER_TO_EVENT";
export const LTWEEKLY_DASHBOARD_GET_EVENT_ATTENDEES =
  "home/LTWEEKLY_DASHBOARD_GET_EVENT_ATTENDEES";
export const INIT_LOGIN_PHASE = "dashboard/INIT_LOGIN_PHASE";
export const LTWEEKLY_DASHBOARD_GET_EVENT_SETTINGS =
  "dashboard/LTWEEKLY_DASHBOARD_GET_EVENT_SETTINGS";
export const LTWEEKLY_AGOLONEWS_BY_DATE =
  "dashboard/LTWEEKLY_AGOLONEWS_BY_DATE";
export const LTWEEKLY_GET_NEWS = "dashboard/LTWEEKLY_GET_NEWS";
export const LTWEEKLY_DASHBOARD_UPDATE_EVENT_SETTINGS =
  "dashboard/LTWEEKLY_DASHBOARD_UPDATE_EVENT_SETTINGS";
export const LTWEEKLY_GET_CURATED_TAGS = "dashboard/LTWEEKLY_GET_CURATED_TAGS";
export const LTWEEKLY_ADD_CURATED_TAGS = "dashboard/LTWEEKLY_ADD_CURATED_TAGS";
export const LTWEEKLY_DASHBOARD_ADD_USER = "LTWEEKLY_DASHBOARD_ADD_USER";
export const LTWEEKLY_THEME_CATEGORIES = "dashboard/LTWEEKLY_THEME_CATEGORIES";
export const LTWEEKLY_CATEGORIES_BY_DATE =
  "dashboard/LTWEEKLY_CATEGORIES_BY_DATE";
export const LTWEEKLY_GET_LT_WEEKLY_THEME =
  "dashboard/LTWEEKLY_GET_LT_WEEKLY_THEME";
export const LTWEEKLY_UPDATE_PRIMARY_SECONDARY_TAGS =
  "dashboard/LTWEEKLY_GET_LT_WEEKLY_THEME";
export const LTWEEKLY_SEND_MAIL_OTHER_NEWS =
  "dashboard/LTWEEKLY_SEND_MAIL_OTHER_NEWS";
export const GET_LTWEEKLY_ITEMS = "dashboard/GET_LTWEEKLY_ITEMS";
export const UPDATE_LTWEEKLY_ITEMS = "dashboard/UPDATE_LTWEEKLY_ITEMS";
export const UPLOAD_IMAGE = "dashboard/UPLOAD_IMAGE";
export const GET_MAILING_LIST = "dashboard/GET_MAILING_LIST";
export const GET_MAILING_LIST_BY_ID = "dashboard/GET_MAILING_LIST_BY_ID";
export const ADD_MAILING_LIST = "dashboard/ADD_MAILING_LIST";
export const REMOVE_MAILING_LIST = "dashboard/REMOVE_MAILING_LIST";
export const REMOVE_THEME_BY_ID = "dashboard/REMOVE_THEME_BY_ID";
export const SEND_LT_WEEKLY_EMAIL = "dashboard/SEND_LT_WEEKLY_EMAIL";
export const ADD_LTWEEKLY = "dashboard/ADD_LTWEEKLY";
export const REMOVE_AGOLO_NEWS_BY_ID = "dashboard/REMOVE_AGOLO_NEWS_BY_ID";
export const UPDATE_ARTICLES = "dashboard/UPDATE_ARTICLES";
export const GET_ARTICLES_FROM_URL = "dashboard/GET_ARTICLES_FROM_URL";
export const UPDATE_LTWEEKLY_OTHER_NEWS =
  "dashboard/UPDATE_LTWEEKLY_OTHER_NEWS";
export const GET_ALL_CAMPAIGNS = "dashboard/GET_ALL_CAMPAIGNS";
export const GET_ALL_CAMPAIGNS_INFO = "dashboard/GET_ALL_CAMPAIGNS_INFO";
export const GET_ALL_CAMPAIGNS_URL_INFO =
  "dashboard/GET_ALL_CAMPAIGNS_URL_INFO";
export const GET_CAMPAIGN_BY_ID = "dashboard/GET_CAMPAIGN_BY_ID";
export const GET_CAMPAIGN_USER_LIST_SEARCH =
  "dashboard/GET_CAMPAIGN_USER_LIST_SEARCH";
export const UPDATE_MAILING_LIST_NAME = "dashboard/UPDATE_MAILING_LIST_NAME";
export const VERIFY_EMAIL_ID = "dashboard/VERIFY_EMAIL_ID";
export const GET_TAGS_COUNT = "dashboard/GET_TAGS_COUNT";
export const GET_THEME_AND_OTHER_NEWS_BY_NAME =
  "dashboard/GET_THEME_AND_OTHER_NEWS_BY_NAME";
export const GET_THEME_AND_OTHER_NEWS_COUNT_BY_NAME =
  "dashboard/GET_THEME_AND_OTHER_NEWS_COUNT_BY_NAME";
export const SEND_EMAIL_TO_LT_TEAM = "dashboard/SEND_EMAIL_TO_LT_TEAM";
export const GET_USERS = "dashboard/GET_USERS";
export const EDIT_USERS = "dashboard/EDIT_USERS";
export const ADD_USERS = "dashboard/ADD_USERS";
export const SYNC_OTHER_NEWS = "dashboard/SYNC_OTHER_NEWS";
export const SYNC_LT_WEEKLY_TO_SITECORE =
  "dashboard/SYNC_LT_WEEKLY_TO_SITECORE";
export const SEND_FREE_FORM_LTWEEKLY_EMAIL =
  "dashboard/SEND_FREE_FORM_LTWEEKLY_EMAIL";
export const SEND_FREE_FORM_LTWEEKLY_EMAIL_BY_ID =
  "dashboard/SEND_FREE_FORM_LTWEEKLY_EMAIL_BY_ID";
  export const  LTWEEKLY_AGOLONEWS_BY_DATE_NEW =  "dashboard/LTWEEKLY_AGOLONEWS_BY_DATE_NEW";
  export const  GENERATE_LT_WEEKLY_PDF =  "dashboard/GENERATE_LT_WEEKLY_PDF";
  export const  LTWEEKLY_ALL_CREATED_AGOLONEWS_DATE="dashboard/LTWEEKLY_ALL_CREATED_AGOLONEWS_DATE";
  export const LTWEEKLY_ALL_CREATED_DRAFT_AGOLONEWS_DATE="dashboard/LTWEEKLY_ALL_CREATED_DRAFT_AGOLONEWS_DATE";
export const ADD_LTWEEKLY_AUTO = "dashboard/ADD_LTWEEKLY_AUTO";
export const UPDATE_LTWEEKLY_ITEMSNEW = "dashboard/UPDATE_LTWEEKLY_ITEMSNEW";
export const UPDATE_LTWEEKLY_DETAILS_AUTO = "dashboard/UPDATE_LTWEEKLY_DETAILS_AUTO"; 
export const ADD_POLL = "dashboard/ADD_POLL";
export const GET_USERS_POLL_DATA = "dashboard/GET_USERS_POLL_DATA";
export const GET_CREATED_POLL_BYID = "dashboard/GET_CREATED_POLL_BYID";
export const EXPORT_LTWEEKLY_THEMES = "dashboard/EXPORT_LTWEEKLY_THEMES";
export const ADD_NEW_LTWEEKLY_ENTRY= "dashboard/ADD_NEW_LTWEEKLY_ENTRY"
export const VALIDATE_TOKEN="dashboard/VALIDATE_TOKEN"
export const UPDATE_OTHERNEWS_THEMES_TAGS ="dashboard/UPDATE_OTHERNEWS_THEMES_TAGS"
export const UPDATE_TAG_NAMES = "dashboard/UPDATE_TAG_NAMES"
export const GET_HEADER_TAG_NAMES= "Tag/GET_HEADER_TAG_NAMES"
export const CREATE_NEW_TAG="Tag/CREATE_NEW_TAG"
export const UPDATE_TAGS_VISIBILITY="Tag/UPDATE_TAGS_VISIBILITY"
export const DELETE_TAG="/Tag/DELETE_TAG"
export const UPDATE_TOP_THEME="/TopLtWeeklyTheme/UPDATE_TOP_THEME"
export const REMOVE_PRIMARY_SEC_TAG="Tag/REMOVE_PRIMARY_SEC_TAG"
export const GET_WEBACCESS_LIST="dashboard/GET_WEBACCESS_LIST"

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  loginPhase: INIT,
  loginData: {},
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ""; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    case LTWEEKLY_DASHBOARD_LOGIN: {
      return state.set("loginPhase", LOADING);
    }

    case LTWEEKLY_DASHBOARD_LOGIN_SUCCESS: {
      const { payload } = action;
      return state.set("loginPhase", SUCCESS).set("loginData", payload.results);
    }

    case LTWEEKLY_DASHBOARD_LOGIN_ERROR: {
      return state.set("loginPhase", ERROR);
    }
    case INIT_LOGIN_PHASE: {
      return state.set("loginOtpPhase", INIT);
    }
    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/
export const getOtp = (value) => {
  return {
    type: LTWEEKLY_DASHBOARD_LOGIN,
    payload: api.login(value),
  };
};

export const verifyOtp = (value) => {
  return {
    type: LTWEEKLY_LOGIN_VERIFY_OTP,
    payload: api.verifyOtp(value),
  };
};
export const getMe = (value) => {
  return {
    type: LTWEEKLY_GET_ME,
    payload: api.getMe(value),
  };
};

export const getAppUsers = (value) => {
  return {
    type: LTWEEKLY_DASHBOARD_GET_APP_USERS,
    payload: api.getAppUsers(value),
  };
};

export const getEvents = (value) => {
  return {
    type: LTWEEKLY_DASHBOARD_GET_EVENTS,
    payload: api.getEvents(value),
  };
};

export const appUserDelete = (value) => {
  return {
    type: LTWEEKLY_DASHBOARD_USER_DELETE,
    payload: api.appUserDelete(value),
  };
};

export const getEventDetails = (value) => {
  return {
    type: LTWEEKLY_DASHBOARD_GET_EVENT_DETAILS,
    payload: api.getEventDetails(value),
  };
};

export const getUserDetails = (value) => {
  return {
    type: LTWEEKLY_DASHBOARD_GET_USER_DETAILS,
    payload: api.getUserDetails(value),
  };
};

export const addEvent = (value) => {
  return {
    type: LTWEEKLY_DASHBOARD_ADD_EVENT,
    payload: api.addEvent(value),
  };
};

export const addAppUserToEvent = (value) => {
  return {
    type: LTWEEKLY_DASHBOARD_ADD_APP_USER_TO_EVENT,
    payload: api.addAppUserToEvent(value),
  };
};

export const getEventAttendees = (value) => {
  return {
    type: LTWEEKLY_DASHBOARD_GET_EVENT_ATTENDEES,
    payload: api.getEventAttendees(value),
  };
};

export const addAppUserToEventAttendees = (value) => {
  return {
    type: LTWEEKLY_DASHBOARD_GET_EVENT_ATTENDEES,
    payload: api.addAppUserToEventAttendees(value),
  };
};

export const removeAppUserToEventAttendees = (value) => {
  return {
    type: LTWEEKLY_DASHBOARD_GET_EVENT_ATTENDEES,
    payload: api.removeAppUserToEventAttendees(value),
  };
};

export const updateAttendeesPermissions = (value) => {
  return {
    type: LTWEEKLY_DASHBOARD_GET_EVENT_ATTENDEES,
    payload: api.updateAttendeesPermissions(value),
  };
};
export const getEventSettings = (value) => {
  return {
    type: LTWEEKLY_DASHBOARD_GET_EVENT_SETTINGS,
    payload: api.getEventSettings(value),
  };
};

export const updateEventSettings = (value) => {
  return {
    type: LTWEEKLY_DASHBOARD_UPDATE_EVENT_SETTINGS,
    payload: api.updateEventSettings(value),
  };
};

export const getAgoloNewsByDate = (value) => {
  return {
    type: LTWEEKLY_AGOLONEWS_BY_DATE,
    payload: api.getAgoloNewsByDate(value),
  };
};

export const getAgoloNewsByDateNew = (value) => {
  return {
    type: LTWEEKLY_AGOLONEWS_BY_DATE_NEW,
    payload: api.getAgoloNewsByDateNew(value), 
  };
};

export const getAllCreatedAgoloNewsDate = (value) => {
  return {
    type: LTWEEKLY_ALL_CREATED_AGOLONEWS_DATE,
    payload: api.getAllCreatedAgoloNewsDate(value),
  };
}

export const getAllCreatedDraftAgoLoNewsDate = (value) => {
  return {
    type: LTWEEKLY_ALL_CREATED_DRAFT_AGOLONEWS_DATE,
    payload: api.getAllCreatedDraftAgoLoNewsDate(value),
  };
}

export const getCategoriesByDate = (value) => {
  return {
    type: LTWEEKLY_CATEGORIES_BY_DATE,
    payload: api.getCategoriesByDate(value),
  };
};

export const sendMailOtherNews = (value) => {
  return {
    type: LTWEEKLY_SEND_MAIL_OTHER_NEWS,
    payload: api.sendMailOtherNews(value),
  };
};

export const addUser = (value) => {
  return {
    type: LTWEEKLY_DASHBOARD_ADD_USER,
    payload: api.addUser(value),
  };
};

export const addCuratedTags = (value) => {
  return {
    type: LTWEEKLY_ADD_CURATED_TAGS,
    payload: api.addCuratedTags(value),
  };
};

export const getCuratedTags = (value) => {
  return {
    type: LTWEEKLY_GET_CURATED_TAGS,
    payload: api.getCuratedTags(value),
  };
};

export const getLtWeeklyTheme = (value) => {
  return {
    type: LTWEEKLY_GET_LT_WEEKLY_THEME,
    payload: api.getLtWeeklyTheme(value),
  };
};

export const AddLTWeekly = (value) => {
  return {
    type: ADD_LTWEEKLY,
    payload: api.AddLTWeekly(value),
  };
};
export const AddLTWeeklyAuto = (value) => {
  return {
    type: ADD_LTWEEKLY_AUTO,
    payload: api.AddLTWeeklyAuto(value),
  };
};

export const UpdateLtweeklyItem = (value) => {
  return {
    type: UPDATE_LTWEEKLY_ITEMSNEW,
    payload: api.UpdateLtweeklyItem(value),
  };
};

export const UpdateLtweeklyDetailsAuto = (value) => {
  return {
    type: UPDATE_LTWEEKLY_DETAILS_AUTO,
    payload: api.UpdateLtweeklyDetailsAuto(value),
  };
};



export const removeAgoloNews = (value) => {
  return {
    type: REMOVE_AGOLO_NEWS_BY_ID,
    payload: api.removeAgoloNews(value),
  };
};

export const updateTags = (value) => {
  return {
    type: LTWEEKLY_UPDATE_PRIMARY_SECONDARY_TAGS,
    payload: api.updateTags(value),
  };
};

export const getNews = (value) => {
  return {
    type: LTWEEKLY_GET_NEWS,
    payload: api.getNews(value),
  };
};

export const getLtWeeklyThemeCategories = (value) => {
  return {
    type: LTWEEKLY_THEME_CATEGORIES,
    payload: api.getLtWeeklyThemeCategories(value),
  };
};

export const getLtWeeklyItems = (value) => {
  return {
    type: GET_LTWEEKLY_ITEMS,
    payload: api.getLtWeeklyItems(value),
  };
};

export const updateLtweeklyDetails = (value) => {
  return {
    type: UPDATE_LTWEEKLY_ITEMS,
    payload: api.updateLtweeklyDetails(value),
  };
};

export const uploadImage = (value) => {
  return {
    type: UPLOAD_IMAGE,
    payload: api.uploadImage(value),
  };
};

export const getMailingListById = (value) => {
  return {
    type: GET_MAILING_LIST_BY_ID,
    payload: api.getMailingListById(value),
  };
};

export const getMailingList = (value) => {
  return {
    type: GET_MAILING_LIST,
    payload: api.getMailingList(value),
  };
};

export const updateMailingList = (value) => {
  return {
    type: ADD_MAILING_LIST,
    payload: api.updateMailingList(value),
  };
};

export const removeMailingList = (value) => {
  return {
    type: REMOVE_MAILING_LIST,
    payload: api.removeMailingList(value),
  };
};

export const removeThemeById = (value) => {
  return {
    type: REMOVE_THEME_BY_ID,
    payload: api.removeThemeById(value),
  };
};

export const sendLtWeeklyEmail = (value) => {
  return {
    type: SEND_LT_WEEKLY_EMAIL,
    payload: api.sendLtWeeklyEmail(value),
  };
};

export const updateArticles = (value) => {
  return {
    type: UPDATE_ARTICLES,
    payload: api.updateArticles(value),
  };
};

export const getArticleFromUrl = (value) => {
  return {
    type: GET_ARTICLES_FROM_URL,
    payload: api.getArticleFromUrl(value),
  };
};

export const UpdateLTWeeklyOthersNews = (value) => {
  return {
    type: UPDATE_LTWEEKLY_OTHER_NEWS,
    payload: api.UpdateLTWeeklyOthersNews(value),
  };
};

export const getAllCampaigns = (value) => {
  return {
    type: GET_ALL_CAMPAIGNS,
    payload: api.getAllCampaigns(value),
  };
};

export const getCampaignClickInfo = (value) => {
  return {
    type: GET_ALL_CAMPAIGNS_INFO,
    payload: api.getCampaignClickInfo(value),
  };
};

export const getCampaignClickUrlsInfo = (value) => {
  return {
    type: GET_ALL_CAMPAIGNS_URL_INFO,
    payload: api.getCampaignClickUrlsInfo(value),
  };
};

export const GetCampaignDetailsById = (value) => {
  return {
    type: GET_CAMPAIGN_BY_ID,
    payload: api.GetCampaignDetailsById(value),
  };
};

export const CampaignSearch = (value) => {
  return {
    type: GET_CAMPAIGN_USER_LIST_SEARCH,
    payload: api.CampaignSearch(value),
  };
};

export const updateMailingListName = (value) => {
  return {
    type: UPDATE_MAILING_LIST_NAME,
    payload: api.updateMailingListName(value),
  };
};

export const getTags = (value) => {
  return {
    type: GET_TAGS_COUNT,
    payload: api.getTags(value),
  };
};

export const VerifyEmail = (value) => {
  return {
    type: VERIFY_EMAIL_ID,
    payload: api.VerifyEmail(value),
  };
};

export const getThemeAndOtherNewByName = (value) => {
  return {
    type: GET_THEME_AND_OTHER_NEWS_BY_NAME,
    payload: api.getThemeAndOtherNewByName(value),
  };
};

export const GetCategoryCount = (value) => {
  return {
    type: GET_THEME_AND_OTHER_NEWS_COUNT_BY_NAME,
    payload: api.GetCategoryCount(value),
  };
};

export const SendEmailToLtTeam = (value) => {
  return {
    type: SEND_EMAIL_TO_LT_TEAM,
    payload: api.SendEmailToLtTeam(value),
  };
};

export const GetUsers = (value) => {
  return {
    type: GET_USERS,
    payload: api.GetUsers(value),
  };
};

export const addProfile = (value) => {
  return {
    type: ADD_USERS,
    payload: api.addProfile(value),
  };
};
export const editProfile = (value) => {
  return {
    type: EDIT_USERS,
    payload: api.editProfile(value),
  };
};

// export const SyncOtherNews = (value) => {
//   return {
//     type: EDIT_USERS,
//     payload: api.SyncOtherNews(value),
//   };
// };

// export const syncLtWeeklyToSitecore = (value) => {
//   return {
//     type: SYNC_LT_WEEKLY_TO_SITECORE,
//     payload: api.syncLtWeeklyToSitecore(value),
//   };
// };

export const sendFreeFormLtEmail = (value) => {
  return {
    type: SEND_FREE_FORM_LTWEEKLY_EMAIL,
    payload: api.sendFreeFormLtEmail(value),
  };
};

export const getSendEmailInformations = (value) => {
  return {
    type: SEND_FREE_FORM_LTWEEKLY_EMAIL_BY_ID,
    payload: api.getSendEmailInformations(value)
  }
}

export const generatePdf = (value) => {
  return {
    type: GENERATE_LT_WEEKLY_PDF,
    payload: api.generatePdf(value)
  }
}

export const resetLoginPhase = (value) => {
  return {
    type: INIT_LOGIN_PHASE,
  };
};
export const AddPoll = (value) => {
  return {
    type: ADD_POLL,
    payload: api.AddPoll(value),
  };
};

export const GetPollUserDetails = (value) => {
  return {
    type: GET_USERS_POLL_DATA,
    payload: api.GetPollUserDetails(value),
  };
}
export const GetPollByLtweeklyId = (value) => {
  return {
    type: GET_CREATED_POLL_BYID,
    payload: api.GetPollByLtweeklyId(value),
  };
}

export const ExportLtWeeklyThemes = (value) => {
  return {
    type: EXPORT_LTWEEKLY_THEMES,
    payload: api.ExportLtWeeklyThemes(value),
  };
}

export const AddNewLTWeeklyEntry =(value) => {
  return {
    type: ADD_NEW_LTWEEKLY_ENTRY,
    payload: api.AddNewLTWeeklyEntry(value)
  }
}

export const ValidateToken=(value)=>{
  return{
    type:VALIDATE_TOKEN,
    payload:api.ValidateToken(value)
  }
}

export const updateTagsOtherNewsandThemes =(value) =>{
  return {
    type:UPDATE_OTHERNEWS_THEMES_TAGS,
    payload:api.updateTagsOtherNewsandThemes(value)
  }
}

export const updateTagsName =(value) => {
  return {
    type:UPDATE_TAG_NAMES,
    payload:api.updateTagsName(value)
  }
}

export const getAllHeaderTags=(value)=>{
  return{
    type:GET_HEADER_TAG_NAMES,
    payload:api.getAllHeaderTags(value)
  }
}
export const CreateTagsName=(value)=>{
  return{
    type:CREATE_NEW_TAG,
    payload:api.CreateTagsName(value)
  }
}
export const UpdateNewsCategoryTagsVisibility=(value)=>{
  return{
    type:UPDATE_TAGS_VISIBILITY,
    payload:api.UpdateNewsCategoryTagsVisibility(value)
  }
}
export const removeThemeandOtherNewsTagById=(value)=>{
  return{
    type:DELETE_TAG,
    payload:api.removeThemeandOtherNewsTagById(value)
  }
}
export const updateTopTheme=(value)=>{
  return{
    type:UPDATE_TOP_THEME,
    payload:api.updateTopTheme(value)
  }
}
export const RemoveTagsOtherNewsandThemes=(value)=>{
  return{
    type:REMOVE_PRIMARY_SEC_TAG,
    payload:api.RemoveTagsOtherNewsandThemes(value)
  }
}
export const GetWebAccessList=(value)=>{
  return{
    type:GET_WEBACCESS_LIST,
    payload:api.GetWebAccessList(value)
  }
}
/***********************************
 * Epics
 ***********************************/
const loginEpic = (action$) =>
  action$.pipe(
    ofType(LTWEEKLY_DASHBOARD_LOGIN),
    mergeMap((action) => {
      return from(api.login(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: LTWEEKLY_DASHBOARD_LOGIN_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: LTWEEKLY_DASHBOARD_LOGIN_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

export const ltweeklyEpic = combineEpics(loginEpic);
